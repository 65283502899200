import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import busyFounders from "src/images/books/busy-founders/book-cover-pages@2x.png"
import ebookCoverSpread from "src/images/books/onboarding-emails/ebook-cover-spread@2x.png"
import bookCover from "src/images/books/double-roi/book_cover@2x.png"

function IndexPage() {
  return (
    <Layout>
      <SEO title="Books" />
      <PageTitle
        preheader="Free To Download"
        title="Our Books"
        subtitle="A collection of Nickelled-authored books to help you grow your business."
      ></PageTitle>

      <div class="pb-24">
        <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={busyFounders}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                The Busy Founder's Guide To User Onboarding
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Learn how to reach growth, revenue and success through better
                  customer experience with this new ebook
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <Link to="/books/guide-to-user-onboarding/" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                    <span class="ml-3">Download</span>
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={ebookCoverSpread}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                The Elements Of The Perfectly Persuasive Onboarding Email
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Learn how to write onboarding emails that users can’t ignore
                  and persuade them to get hands on with your web apps.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                <Link to="/books/designing-and-writing-persuasive-onboarding-emails/" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                    <span class="ml-3">Download</span>
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={bookCover}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                Doubling the ROI of Contextual Messaging
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  A simple guide to increasing the return on investment of
                  contextual messaging.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                <Link to="/books/doubling-roi-of-contextual-messaging/" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                    <span class="ml-3">Download</span>
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage
